import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';
import ThirtyThreeSplitContainer from '../../containers/ThirtyThreeSplitContainer/ThirtyThreeSplitContainer';

const RelatedBlock = ({ fields, rendering }) => {
  return (
    <div className="related-block">
      <div className="l-padding ">
        <Text field={fields.title} tag="h2" />
        <ThirtyThreeSplitContainer
          rendering={rendering}
          noPadding
          animation={fields.hasFade.value}
        />
      </div>
    </div>
  );
};

RelatedBlock.propTypes = PropTypes.SitecoreItem.inject({
  title: PropTypes.SingleLineText,
  hasFade: PropTypes.Checkbox,
});

RelatedBlock.defaultProps = DefaultProps.SitecoreItem.inject({
  title: DefaultProps.SingleLineText,
  hasFade: DefaultProps.Checkbox,
});

export default RelatedBlock;
